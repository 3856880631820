import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;

const ContentReports = () => {
  const { token, apiUrl } = useContext(Context);

  const [activeTab, setActiveTab] = useState("2");
  const [filters, setFilters] = useState({
    date: moment().subtract(2, "days").format('YYYY-MM-DD'),
    plan: null,
    paid: null,
    currency: null,
    model: null,
    country: null,
    platform: null,
    content: null,
  });
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  //const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(() => {
    setData2([])
    setData3([])
    setFilters({
      ...filters,
      date: moment().subtract(2, "days").format('YYYY-MM-DD'),
    })
  }, [apiUrl, token, activeTab])

  useEffect(() => {
    Fetch(
      `${apiUrl}/movies`,
      token,
      (result) => {
        const m = convertIdToKey(result.movies)
        setMovies(m)
      }
    )
    Fetch(
      `${apiUrl}/series`,
      token,
      (result) => {
        const m = convertIdToKey(result.series)
        setSeries(m)
      }
    )
  }, [apiUrl, token, filters])

  useEffect(() => {
    if (!filters.date) {
      return
    }

    Fetch(
      `${apiUrl}/reports/hau_video?query_date=${filters.date}`,
      token,
      (result) => {
        setData2(result.data.sort((a, b) => a.hour - b.hour))
      }
    )
    Fetch(
      `${apiUrl}/reports/content_report?query_date=${filters.date}`,
      token,
      (result) => {
        setData3(result.data.sort((a, b) => b.uniqueUsers - a.uniqueUsers))
      }
    )
  }, [apiUrl, token, filters]);

  const customfilters = (
    <>
      {" "}
      <Row style={{ marginBottom: "20px" }} align={"middle"}>
        {["1", "2", "3", "4", "5"].includes(activeTab) && (
          <Col span={6}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(value, dateString) =>
                setFilters({
                  ...filters,
                  date: dateString ? dateString : "",
                })
              }
              format={dateFormat}
              defaultValue={dayjs().subtract(2, 'days')}
            />
          </Col>
        )}
        {["1"].includes(activeTab) && (
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Search By Content"}
              allowClear
              showSearch
              onChange={(value) => setFilters({ ...filters, content: value })}
            ></Select>
          </Col>
        )}
        {["1"].includes(activeTab) && (
          <Col
            span={8}
            align="right"
            push={activeTab == "1" ? 4 : 6}
            style={{ fontSize: 18 }}
          >
            {activeTab == "1" ? ` Total Minutes of consumption - ${0}` : null}
          </Col>
        )}
      </Row>
    </>
  );

  const extractContentName = (record) => {
    for (const m of movies) {
      if (m.key === record.content)
        return m.title
    }
    for (const s of series) {
      if (s.key === record.content)
        return s.title
    }
  }

  return (
    <div className="manage-orders-wrapper">
      <Tabs
        defaultActiveKey="2"
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v)}
      >
        {/* <TabPane tab="Total Minutes consumed" key="1">
          {customfilters}
          <Table dataSource={data1}>
            <Column
              title="Content Title"
              dataIndex="content"
              key="content"
              align="center"
              render={(_, record) => extractContentName(record)}
            />
            <Column
              title="Minutes viewed"
              dataIndex={"minutes"}
              key="minutes"
              align="center"
            />
          </Table>
        </TabPane> */}
        <TabPane tab="Hourly concurrent users" key="2">
          {customfilters}
          <Table dataSource={data2} pagination={false}>
            <Column
              title="Hour (1-24)"
              dataIndex="hour"
              key="hour"
              align="center"
            />
            <Column
              title="No of Users"
              dataIndex="users"
              key="users"
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="Content reports" key="3">
          {customfilters}
          <Table
            dataSource={data3}
            pagination={false}
          >
            <Column
              title="Content Title"
              dataIndex="title"
              key="title"
              align="center"
            />
            <Column
              title="Duration"
              dataIndex="duration"
              key="duration"
              align="center"
            />
            <Column
              title="No of UU"
              dataIndex="uniqueUsers"
              key="uniqueUsers"
              align="center"
            />
            <Column
              title="APD in mins"
              dataIndex="avgWatched"
              key="avgWatched"
              align="center"
            />
            <Column
              title="Progress (%)"
              dataIndex="avgProg"
              key="avgProg"
              align="center"
            />
          </Table>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ContentReports;
