import { useState, useEffect, useContext } from 'react'
import {
  Table,
  InputNumber,
  Modal,
  Select,
  Radio,
  Form,
  Upload,
  Space,
  notification,
  message
} from 'antd'
import { PlusCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons'

import { Context } from '../../context'
import { Delete, Fetch, Patch, Put } from '../../fetch'
import { convertIdToKey, removeUnpublished } from '../../utils'
import Column from 'antd/lib/table/Column'
import UploadBanners from '../UploadBanners/index'

const { Option } = Select
const { confirm } = Modal

const ManageBanners = () => {
  const { apiUrl, token } = useContext(Context)

  const [form] = Form.useForm()
  const [page, setPage] = useState()
  const [banners, setBanners] = useState([])
  const [bannerType, setBannerType] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [movies, setMovies] = useState([])
  const [series, setSeries] = useState([])
  const [songs, setSongs] = useState([])
  const [bannerImageUrl, setBannerImageUrl] = useState()
  const [counter, setCounter] = useState(0)
  const [promoId, setPromoId] = useState()

  const handleChange = (page) => {
    setPage(page)
  }

  useEffect(() => {
    if (page) {
      Fetch(`${apiUrl}/promos?filter=${page}`, token, (result) => {
        const banners = convertIdToKey(result.promos)
        setBanners(banners)
      })
    }

    Fetch(`${apiUrl}/movies`, token, (result) => {
      const movies = removeUnpublished(convertIdToKey(result.movies))
      setMovies(movies)
    })

    Fetch(`${apiUrl}/series`, token, (result) => {
      const series = removeUnpublished(convertIdToKey(result.series))
      setSeries(series)
    })

    Fetch(`${apiUrl}/songs`, token, (result) => {
      const songs = removeUnpublished(convertIdToKey(result.songs))
      setSongs(songs)
    })
  }, [apiUrl, token, page, counter])

  const handleSubmit = async () => {
    setConfirmLoading(true)
    try {
      const values = await form.validateFields()
      const fn = modalType === 'Add' ? Patch : Put
      fn(
        modalType === 'Add' ? `${apiUrl}/promos` : `${apiUrl}/promos/${promoId}`,
        token,
        { ...values, page },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Banners updated'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setConfirmLoading(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Edit Banners Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      notification.error({
        message: 'Edit Banners Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
      setBannerImageUrl("")
    }
  }

  const extractNameByType = (record) => {

    if ('movie' in record && ['movies', 'home', 'movies-kids'].includes(record.page)) {
      for (const movie of movies) {
        if (movie.key === record.movie) {
          return movie.title
        }
      }
    }
    else if ('series' in record && ['series', 'home', 'series-kids'].includes(record.page)) {
      for (const s of series) {
        if (s.key === record.series) {
          return s.title
        }
      }
    }
    else if ('song' in record && ['songs', 'home', 'songs-kids'].includes(record.page)) {
      for (const song of songs) {
        if (song.key === record.song) {
          return song.title
        }
      }
    }
    else return "-";
  }

  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this banner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/promos/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Promo deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting promo',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const showEditModal = (record) => {
    setModalType("Edit")
    setPromoId(record.key)
    form.setFieldsValue(record)
    setBannerImageUrl(record.bannerImage)
    setModalVisible(true)

    if (page === 'home') {
      setBannerType(record.promoType)
    }
  }

  const filterMaturity = (video, page) => {
    if (page.includes('kids')) {
      return video.maturity === 'U';
    }
    return true
  }

  return (
    <div className="manage-episodes-wrapper">
      <Space direction='horizontal' size={"middle"}>
        <Select
          style={{ width: 200 }}
          placeholder="Select Page"
          showSearch
          onChange={handleChange}
        >
          <Option value="home">Home</Option>
          <Option value="movies">Movies</Option>
          <Option value="series">Series</Option>
          <Option value="songs">Songs</Option>
          {/* <Option value="home-kids">Home Kids</Option>
        <Option value="movies-kids">Movies Kids</Option>
        <Option value="series-kids">Series Kids</Option>
        <Option value="songs-kids">Songs Kids</Option> */}
        </Select>
      </Space>
      <Table dataSource={banners}>
        {(page === 'home' || page === 'home-kids') && <Column title="Promo Type" dataIndex="promoType" key="promoType" align="center" />}
        <Column
          title="Name"
          render={(_, record) => extractNameByType(record)}
          align="center"
        />
        <Column title="Position" dataIndex="position" key="position" align="center" />
        <Column title="Banner" dataIndex="bannerImage" key="bannerImage" align="center" render={(value) => <img src={value + '/public'} style={{ width: '250px' }} />} />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => { showEditModal(record) }}>Edit</a>
              <a onClick={() => showConfirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }} className="add-series-btn" onClick={() => { setModalVisible(true); setModalType("Add") }} />
      <Modal
        title="Modify Banner"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => { setModalVisible(false); form.resetFields() }}
        confirmLoading={confirmLoading}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form layout="vertical" form={form}>
          {(page === "home" || page === "home-kids") && (
            <Form.Item name="promoType" label="Type" rules={[{ required: true }]}>
              <Radio.Group className="content-type-option" onChange={(e) => setBannerType(e.target.value)}>
                <Radio value="movie">Movie</Radio>
                <Radio value="series">Series</Radio>
                <Radio value="song">Song</Radio>
                <Radio value="static">Static</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {(page === "movies" || page === "movies-kids" || bannerType === "movie") && (
            <Form.Item name="movie" label="Select Movie" rules={[{ required: true }]}>
              <Select
                showSearch
                className="form-card-selector"
                optionFilterProp="children"
              >
                {movies.filter(video => filterMaturity(video, page)).map(movie => (
                  <Option key={movie.key} value={movie.key}>{movie.title}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(page === "series" || page === "series-kids" || bannerType === "series") && (
            <Form.Item name="series" label="Select Series" rules={[{ required: true }]}>
              <Select
                showSearch
                optionFilterProp="children"
                className="form-card-selector"
              >
                {series.filter(video => filterMaturity(video, page)).map(s => (
                  <Option key={s.key} value={s.key}>{s.title}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(page === "songs" || page === "songs-kids" || bannerType === "song") && (
            <Form.Item name="song" label="Select Song" rules={[{ required: true }]}>
              <Select
                showSearch
                optionFilterProp="children"
                className="form-card-selector"
              >
                {songs.filter(video => filterMaturity(video, page)).map(song => (
                  <Option key={song.key} value={song.key}>{song.title} </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name="position" label="Position" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="bannerImage" label="Banner" rules={[{ required: true }]}>
            <UploadBanners name={"bannerImage"} updateForm={form} />
          </Form.Item>
          <Form.Item name="mobileImage" label="Mobile Banner" rules={[{ required: true }]}>
            <UploadBanners name={"mobileImage"} updateForm={form} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageBanners
