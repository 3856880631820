import { useEffect, useState } from 'react'
import { Button, Form, Input, notification } from 'antd'

import 'antd/dist/antd.css'
import './App.css';

import { Context } from './context'
import { parseJwt } from './utils'
import AdminModule from './components/AdminModule'

const App = () => {
  let apiUrl = ''
  let transcodeUrl = 'https://transcode.aaonxt.com'
  if (process.env.NODE_ENV === 'development') {
    apiUrl = 'http://localhost:8000'
  } else if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://aao-api.ibee.ai'
  }

  const [signedIn, setSignedIn] = useState(false)
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [superAdmin, setSuperAdmin] = useState(false)
  const [initialTab, setInitialTab] = useState("dashboard")

  const [form] = Form.useForm()

  useEffect(() => {
    const token = sessionStorage.getItem('access_token')
    if (token) {
      setToken(token)
      setSignedIn(true)
    }
    const email = sessionStorage.getItem('user_email')
    if (email) {
      setUserEmail(email)
    }

    const isSuperAdmin = sessionStorage.getItem('superadmin')
    if (isSuperAdmin) {
      isSuperAdmin === 'true' ? setSuperAdmin(true) : setSuperAdmin(false)
    }

    const initialTab = sessionStorage.getItem('lastNav')
    if (initialTab) {
      setInitialTab(initialTab)
    }
  }, [])

  const handleLoginSubmit = () => {
    setLoading(true)

    const formData = new FormData()
    formData.append('username', form.getFieldValue('username'))
    formData.append('password', form.getFieldValue('password'))
    formData.append('client_id', 'admin')

    fetch(`${apiUrl}/oauth2/token`, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.status)
      }
      return response.json()
    })
    .then(result => {
      sessionStorage.setItem('access_token', result['access_token'])
      setToken(result['access_token'])
      setSignedIn(true)
      sessionStorage.setItem('user_email', form.getFieldValue('username'))
      setUserEmail(form.getFieldValue('username'))
      const jwt = parseJwt(result['access_token'])
      sessionStorage.setItem('superadmin', jwt.superadmin)
      setSuperAdmin(jwt['superadmin'])
    }).catch(error => {
      if (error.message === "401") {
        notification.error({
          message: 'Error',
          description: 'Invalid username/password'
        })
      }
      console.error('There is a problem with fetch operation: ', error)
    }).finally(() => setLoading(false))
  }

  if (signedIn) {
    return (
      <Context.Provider value={{ token, apiUrl, transcodeUrl, superAdmin }}>
        <AdminModule userEmail={userEmail} initialTab={initialTab} />
      </Context.Provider>
    )
  } else {
    return (
      <div className="login-form">
        <img
          src="/images/logo.svg"
          className="logo"
          alt="MiniPix"
        />
        <Form form={form} layout="vertical" onFinish={handleLoginSubmit}>
          <Form.Item label="Username" name="username">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input type="password" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" disabled={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default App;
