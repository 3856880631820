import { useContext, useEffect, useState } from 'react'
import { DatePicker, Select, Table, Row, Col, message, Checkbox, Switch, Space } from 'antd'
import moment from 'moment';

import './styles.css'

import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import { CheckCircleFilled, CloseCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'

const { Column } = Table
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const ManageOrders = () => {
    const { token, apiUrl } = useContext(Context)

    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState({ date: [moment().startOf('month'), moment().endOf('month')], plan: null, reseller: null, paid: null, currency: null });
    const [plans, setPlans] = useState([]);
    const [applePlans, setApplePlans] = useState([]);
    const [androidPlans, setAndroidPlans] = useState([]);
    const [resellerPlans, setRessellerPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]);

    const handleTableDataChange = (page) => {
        let f = filters;
        let planstring = f.plan ? `plan=${f.plan}` : ``;
        let resellerPlanString = f.reseller ? `plan=${f.reseller}` : ``
        let sdate = moment(f.date[0]).isValid() ? moment(f.date[0]).format(dateFormat) : null
        let edate = moment(f.date[1]).isValid() ? moment(f.date[1]).format(dateFormat) : null
        let datestring = sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
        let paidstring = f.paid ? `paid=${true}` : ``;
        let currencystring = f.currency ? `currency=${f.currency}` : ``;
        let fstring = `${planstring}&${resellerPlanString}&${datestring}&${paidstring}&${currencystring}`

        Fetch(
            `${apiUrl}/orders?current=${page.current}&size=${page.pageSize}&${fstring}`,
            token,
            (result) => {
                const orders = convertIdToKey(result.orders)
                setOrders(orders)
                setPagination(prev => {
                    return {
                        ...page,
                        total: result.total
                    }
                })
            }
        )
    }


    useEffect(() => {
        handleTableDataChange(pagination)
        if ('length' in plans && plans.length === 0) {
            Fetch(
                `${apiUrl}/plans/admin`, token, (result) => {
                    const plans = convertIdToKey(result.plans)

                    plans.forEach(plan => {
                        const type_of_isprivate = typeof (plan.isPrivate);

                        if ((type_of_isprivate == "undefined") || (plan.isPrivate == "False")) {
                            plan.isPrivate = "public";
                        } else {
                            plan.isPrivate = "private";
                        }
                    })
                    setRessellerPlans(plans)
                })

            Fetch(`${apiUrl}/plans?type=web`, token, (result) => {
                const plans = convertIdToKey(result.plans)
                plans.forEach(plan => {
                    const type_of_isprivate = typeof (plan.isPrivate);

                    if ((type_of_isprivate === "undefined") || (plan.isPrivate === "False")) {
                        plan.isPrivate = "public";
                    } else {
                        plan.isPrivate = "private";
                    }
                })
                setPlans(plans)
            })

            Fetch(`${apiUrl}/plans?type=apple`, token, (result) => {
                const plans = convertIdToKey(result.plans)
                plans.forEach(plan => {
                    plan.name = plan.planId
                })
                setApplePlans(plans)
            })


            Fetch(`${apiUrl}/plans?type=android`, token, (result) => {
                const plans = convertIdToKey(result.plans)
                plans.forEach(plan => {
                    plan.name = plan.planId
                })
                setAndroidPlans(plans)
            })
        }
    }, [apiUrl, token, filters])

    useEffect(() => {
        if (allPlans.length === 0 && resellerPlans.length !== 0 && plans.length !== 0 && applePlans.length !== 0 && androidPlans.length !== 0) {
            let temp_plans = []
            for (let i = 0; i < plans.length; i++) {
                temp_plans.push(plans[i])
            }
            for (let i = 0; i < applePlans.length; i++) {
                temp_plans.push(applePlans[i])
            }
            for (let i = 0; i < androidPlans.length; i++) {
                temp_plans.push(androidPlans[i])
            }
            setAllPlans(temp_plans)
        }
    }, [resellerPlans, plans, androidPlans, applePlans])

    const recheckPayment = (id) => {
        setLoading(true)
        let url = `${apiUrl}/pporders/${id}`;
        if (id && id.includes('order_'))
            url = `${apiUrl}/orders/${id}`;
        Fetch(url,
            token,
            (result) => {
                if (result.paid) {
                    setOrders(orders.map(order => order.key === id ? { ...order, paid: true } : order))
                }
                setLoading(false)
            }
        )
    }


    return (
        <div className="manage-orders-wrapper">
            <Row style={{ marginBottom: "20px" }} align={'middle'} >

                <Col span={2} > Paid <Switch checked={filters.paid ? true : false}
                    onChange={(checked) => { setFilters({ ...filters, paid: checked ? true : null }); setPagination({ current: 1, pageSize: 10 }) }} />
                </Col>
                <Col span={3} >
                    <Select style={{ width: "100%" }} placeholder={"Filter by currency"} allowClear
                        onChange={(value) => { setFilters({ ...filters, currency: value }); setPagination({ current: 1, pageSize: 10 }) }}
                    >
                        {['INR', 'EUR', 'USD', 'SGD', 'AED', 'NPR', 'MPR'].map(code =>
                            <Select.Option key={code} value={code}  >{code}</Select.Option>)}
                    </Select>
                </Col>
                <Col span={4}>
                    <Select style={{ width: "100%" }}
                        placeholder={"Filter by Plan"} showSearch value={filters.plan}
                        allowClear onChange={(value) => { setFilters({ ...filters, plan: value, reseller: null }); setPagination({ current: 1, pageSize: 10 }) }}>
                        {allPlans.map((plan) => {
                            return <Select.Option key={plan.name} value={plan.name}>{plan.name}</Select.Option>
                        })}
                    </Select>
                </Col>
                <Col span={4}>
                    <Select style={{ width: "100%" }}
                        placeholder={"Filter by Reseller Plan"} showSearch value={filters.reseller}
                        allowClear onChange={(value) => { setFilters({ ...filters, reseller: value, plan: null }); setPagination({ current: 1, pageSize: 10 }) }}>
                        {resellerPlans.map((plan) => {
                            return <Select.Option key={plan.name} value={plan.name}>{plan.name}</Select.Option>
                        })}
                    </Select>
                </Col>
                <Col span={7}>
                    <DatePicker.RangePicker style={{ width: "100%" }}
                        defaultValue={[moment(filters.date[0], dateFormat), moment(filters.date[1], dateFormat)]}
                        onChange={(value, dateString) => { setFilters({ ...filters, date: dateString ? dateString : [null, null] }); setPagination({ current: 1, pageSize: 10 }) }}
                        format={dateFormat}
                    /></Col>
                <Col>
                </Col>
            </Row>

            <Table
                dataSource={orders}
                pagination={pagination}
                onChange={handleTableDataChange}
            >
                <Column title="ID" dataIndex="key" key="key" align="center" />
                <Column title="User" dataIndex="user" key="language" align="center" />
                <Column title="Date" dataIndex="date" key="date" align="center" render={(_, record) => moment(record.date).format(dateFormat)} />
                <Column title="Plan" dataIndex="plan" key="plan" align="center"
                />
                <Column title="Amount" dataIndex="amount" key="amount" align="center" render={(_, record) => `${record.currency} ${record.amount}`} />
                <Column title="Discount Used" dataIndex="discountCode" key="discountCode" align="center" render={code => code === 'None' ? '-' : code} />
                <Column
                    title="Paid"
                    dataIndex="paid"
                    key="paid"
                    align="center"
                    render={paid => paid ? <CheckCircleFilled /> : <CloseCircleOutlined />}
                />
                <Column title="Actions" align="center" render={(_, record) => record.paid ? '-' : <a onClick={() => recheckPayment(record.key)} disabled={loading}>Recheck Status</a>} />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'orders')} />
        </div>
    )
}

export default ManageOrders
