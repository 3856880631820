import { useContext, useEffect, useState } from 'react'
import {
  Select, Space,
  Table, message, Tooltip,
  Input, notification, Modal, Tag,
  Form, Button
} from 'antd'

import { Context } from "../../context"
import { Fetch, Post, Delete, Put } from "../../fetch"
import { PlusCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons'

const { Option } = Select
const { Column } = Table
const { confirm } = Modal

const ManageResellers = () => {
  const [editForm] = Form.useForm();
  const [createForm] = Form.useForm();
  const { apiUrl, token } = useContext(Context)
  const [editReseller, setEditReseller] = useState({ show: false });
  const [filterval, setFilterval] = useState(null);
  const [allResellers, setAllResellers] = useState([]);
  const [allPlans, setAllPlans] = useState([]);

  const getAllResellers = () => {
    Fetch(`${apiUrl}/resellers`, token, (result) => {
      if ('resellers' in result)
        setAllResellers(result.resellers);
    })
  }

  const getAllPlans = () => {
    Fetch(`${apiUrl}/plans/admin`, token, (result) => {
      if ('plans' in result)
        setAllPlans(result.plans)
    })
  }

  /* Delete Reseller */
  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this Reseller?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/resellers/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Reseller deleted successfully'
              });
              getAllResellers()
            }
          },
          () => {
            notification.error({
              message: 'Error deleting Reseller',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  useEffect(() => {
    getAllResellers();
    getAllPlans();
  }, [])

  const clickResellerSave = (values) => {
    let query = editReseller && '_id' in editReseller ? `/${editReseller._id}` : ``;

    let fn = editReseller && '_id' in editReseller ? Put : Post;

    fn(
      `${apiUrl}/resellers${query}`,
      token,
      {
        ...values,
        name: values.company,
      },
      result => {
        console.log('reseller result - ', result)
        if (result.success) {
          setEditReseller({ show: false });
          getAllResellers();
          message.success(`Reseller ${editReseller && '_id' in editReseller ? "Updated" : "Created"} Successfully`);
        } else {
          setEditReseller({ show: false });
          message.error(`Error in ${editReseller && '_id' in editReseller ? "Updating" : "Creating"} Reseller`);
        }
      },
      () => {
        console.error(`Error Saving reseller`);
      }
    )
  }



  return (
    <div style={{ margin: '2%' }}>

      {/* Edit Reseller */}
      <Modal title={`${editReseller.flag} Reseller`}
        visible={editReseller.show && editReseller.flag === 'Update'}
        onCancel={() => setEditReseller({ show: false })}
        footer={null} destroyOnClose={true}
        afterClose={() => {
          setEditReseller({ show: false });
          editForm.resetFields();
        }
        }>
        <>
          <Form onFinish={clickResellerSave} form={editForm} layout="vertical"
          >

            <Form.Item name={'company'} label={'Company Name'} required={true}
              initialValue={'company' in editReseller ? editReseller.name : null}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'phone'} label={'Phone'}
              initialValue={'phone' in editReseller ? editReseller.phone : null}
            >
              <Input addonBefore={+91} />
            </Form.Item>
            <Form.Item name={'email'} label={'Email ID'} required={true}
              initialValue={'email' in editReseller ? editReseller.email : null}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'gstin'} label={'GSTIN'} required={true}
              initialValue={'gstin' in editReseller ? editReseller.gstin : null}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'city'} label={'City'} required={true}
              initialValue={'city' in editReseller ? editReseller.city : null}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'state'} label={'State'} required={true}
              initialValue={'state' in editReseller ? editReseller.state : null}
            >
              <Input />
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                {editReseller.flag}
              </Button>
            </Form.Item>

          </Form></>
      </Modal>

      {/* Create New Reseller */}
      <Modal title={`${editReseller.flag} Reseller`}
        visible={editReseller.show && editReseller.flag === 'Create'}
        onCancel={() => setEditReseller({ show: false })}
        footer={null} destroyOnClose
        afterClose={() => setEditReseller({ show: false })}>
        <>
          <Form onFinish={clickResellerSave} form={createForm} layout="vertical"
          >
            <Form.Item name={'company'} label={'Company Name'} required={true}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'phone'} label={'Phone'}
            >
              <Input addonBefore={+91} />
            </Form.Item>
            <Form.Item name={'email'} label={'Email ID'} required={true}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'gstin'} label={'GSTIN'} required={true}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'city'} label={'City'} required={true}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'state'} label={'State'} required={true}
            >
              <Input />
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                {editReseller.flag}
              </Button>
            </Form.Item>

          </Form></>
      </Modal>

      <><Select
        style={{ width: "250px", marginBottom: '20px' }}
        placeholder="Search By Reseller Name"
        showSearch
        allowClear
        onClear={() => setFilterval(null)}
        onChange={(value) => setFilterval(value)}
        optionFilterProp="children"
      >
        {allResellers.map(m => (
          <Option value={m._id}>{m.name}/{m.company}</Option>
        ))}
      </Select>
        <Table
          dataSource={allResellers.filter((rec) =>
            filterval ? rec._id === filterval : true)}
          rowKey={(record) => record._id}
        >

          <Column title="Reseller ID" dataIndex="_id" key="_id" align="center" render={(_, record) => record._id} />
          <Column title="Company Name" dataIndex="name" key="name" align="center" />
          <Column title="Email" dataIndex="email" key="email" align="center" />

          <Column title="API Key" dataIndex="api_secret" key="api_secret" align="center"
            render={(_, record) =>
              <Tag onClick={() => {
                navigator.clipboard.writeText(record.apiKey);
                message.success("Api details copied");
              }}> Click to copy</Tag>
            } />
          <Column title="Status" dataIndex="active" key="active" align="center" render={(_, record) => (
            record.active ? (<Tag color="success">Active</Tag>) : (<Tag color="error">Inactive</Tag>)
          )} />
          <Column title="Actions" align="center" render={(_, record) => (
            <Space direction='horizontal'>
              <Tooltip title="Edit Reseller">
                <Button type='link' onClick={() =>
                  setEditReseller({ ...record, flag: "Update", show: true })}>
                  Edit
                </Button>
              </Tooltip>

              <Tooltip title="Delete Reseller">
                <Button type='link' onClick={() =>
                  showConfirmDelete(record._id)} disabled={!record.active}>
                  Delete
                </Button>
              </Tooltip>

            </Space>
          )} />
        </Table>
        <PlusCircleTwoTone twoToneColor="rgb(243, 101, 35)" style={{ fontSize: "48px" }}
          className="add-plan-btn" onClick={() => {
            setEditReseller({
              flag: "Create", show: true,
              name: '', company: "", email: '', gstin: ''
            })
          }} />
      </>
    </div>
  )
}

export default ManageResellers;
