import {
  Input,
  Tabs,
  Select,
  Card,
  Upload,
  message,
  Col,
  Row,
  Popconfirm,
  Typography,
  Tag,
  Button,
  Space,
  Divider,
} from "antd";
import { FileOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { languageCodeList } from "../../langmap";

const getmimeType = {
  trailers: "video/mp4",
  audios: "audio/mp4",
  thumbnails: "image/*",
  subtitles: "text/*",
  video: "video/mp4",
  posters: "image/*",
};

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const UploadSubtitles = (props) => {
  const { token, apiUrl } = useContext(Context);
  const [language, setLanguage] = useState(null);

  const deleteMedia = (lang) => {
    var data = new FormData();
    data.append("file", null);
    data.append("ob_type", props.ob_type);
    data.append("ftype", "subtitles");
    data.append("seasonid", props.seasonid ? props.seasonid : "n");
    data.append("episodeid", props.episodeid ? props.episodeid : "a");
    data.append("custom_key", " ");
    data.append("language", lang);
    data.append("url", " ");
    data.append("filename", " ");
    data.append("mime_type", " ");
    data.append("filesize", 0);
    fetch(`${apiUrl}/uploads/${props.activeId}`, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      headers: { Authorization: `Bearer ${token}` },
      body: data,
    })
      .then((result) => {
        console.log(result);
        message.success("Delete successful ");
      })
      .catch((err) => {
        console.error(`Error transcoding content`);
      });
  };

  return (
    <>
      <Space direction="horizontal">
        <Select
          placeholder={"select language of subtitle to upload"}
          clearIcon
          showSearch
          style={{ width: 300 }}
          value={language}
          onChange={(value) => setLanguage(value)}
        >
          {languageCodeList.map((v) => (
            <Select.Option key={v["code"]} value={v["code"]}>
              {v["native"]} - {v["name"]}
            </Select.Option>
          ))}
        </Select>
        {language ? (
          <Upload
            listType="text"
            action={`${apiUrl}/uploads/subtitles/${props.upload._id}?language=${language}`}
            accept=".srt,.vtt"
            headers={{
              authorization: `Bearer ${token}`,
            }}
            progress={{
              strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
              },
              strokeWidth: 3,
              format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
            }}
          >
            <Button>Click to Upload</Button>
          </Upload>
        ) : null}
      </Space>
      <Divider />
      {props.subtitles
        ? Object.entries(props.subtitles).map(([k, v]) => (
            <Row>
              <Col>
                <a href={v} target="_blank">
                  <FileOutlined style={{ fontSize: "20px" }}></FileOutlined>
                </a>
              </Col>
              <Col>
                <Input
                  style={{ border: 1, marginTop: "-5px" }}
                  value={k}
                  readOnly
                  onChange={(value) => setLanguage(value)}
                ></Input>{" "}
              </Col>
              <Col>
                <Popconfirm
                  placement="top"
                  title={`Do you want to delete ${k} Subtitles?`}
                  onConfirm={() => {}}
                  okText={"Delete"}
                  cancelText="Cancel"
                  okType="danger"
                  icon={null}
                >
                  <CloseCircleFilled
                    size={"large"}
                    style={{ fontSize: "15px" }}
                  >
                    {" "}
                  </CloseCircleFilled>
                </Popconfirm>
              </Col>
            </Row>
          ))
        : null}
    </>
  );
};

export default UploadSubtitles;
