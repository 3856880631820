import { Table, Select, Row, Col, DatePicker, notification, Modal, Popconfirm, Input } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import moment from 'moment';

import './styles.css'

import { Context } from '../../context'
import { Fetch, Post } from '../../fetch'
import { convertIdToKey, convertSubscriptions, handleExportClick } from '../../utils'

const { Column } = Table
const { Option } = Select
const { confirm } = Modal

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const disabledDate = (current) => {
    return current && current > moment().endOf('day')
}

const ManageUsers = () => {
    const { token, apiUrl, superAdmin } = useContext(Context)

    const [users, setUsers] = useState([])
    const [user, setUser] = useState("")
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    const [searchValue, setSearchValue] = useState()
    const [filters, setFilters] = useState({ date: [moment().startOf('month'), moment().endOf('month')], activeSubscription: null })
    const [reason, setReason] = useState("")
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        Fetch(`${apiUrl}/users`, token, (result) => {
            const users = convertSubscriptions(convertIdToKey(result.users))
            users.forEach(user => {
                if (!user.mobile) {
                    user.mobile = "-"
                }

                if (!user.email) {
                    user.email = "-"
                }
            })
            setUsers(users)
            setPagination(p => {
                return {
                    ...pagination,
                    total: result.total
                }
            })
        })
    }, [apiUrl, token])

    useEffect(() => {
        let url;
        const startDate = moment(filters.date[0]).isValid() ? moment(filters.date[0]).format(dateFormat) : null
        const endDate = moment(filters.date[1]).isValid() ? moment(filters.date[1]).format(dateFormat) : null
        if (startDate && endDate && filters.activeSubscription) {
            url = `${apiUrl}/users?start_date=${startDate}&end_date=${endDate}&active_subscription=${filters.activeSubscription}`
        } else if (filters.activeSubscription) {
            url = `${apiUrl}/users?active_subscription=${filters.activeSubscription}`
        } else if (startDate && endDate) {
            url = `${apiUrl}/users?start_date=${startDate}&end_date=${endDate}`
        } else {
            url = `${apiUrl}/users`
        }

        Fetch(url, token, result => {
            const users = convertSubscriptions(convertIdToKey(result.users))
            users.forEach(user => {
                if (!user.mobile) {
                    user.mobile = "-"
                }

                if (!user.email) {
                    user.email = "-"
                }
            })
            setUsers(users)
            setPagination(p => {
                return {
                    ...pagination,
                    total: result.total
                }
            })
        })
    }, [apiUrl, token, filters])

    const handleSearchChange = (searchText) => {
        setSearchValue(searchText)
    }

    const handleTableDataChange = (paginationParam) => {
        let url;
        const startDate = moment(filters.date[0]).isValid() ? moment(filters.date[0]).format(dateFormat) : null
        const endDate = moment(filters.date[1]).isValid() ? moment(filters.date[1]).format(dateFormat) : null
        if (startDate && endDate && filters.activeSubscription) {
            url = `${apiUrl}/users?start_date=${startDate}&end_date=${endDate}&active_subscription=${filters.activeSubscription}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else if (filters.activeSubscription) {
            url = `${apiUrl}/users?active_subscription=${filters.activeSubscription}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else if (startDate && endDate) {
            url = `${apiUrl}/users?start_date=${startDate}&end_date=${endDate}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else {
            url = `${apiUrl}/users?current=${paginationParam.current}&size=${paginationParam.pageSize}`
        }

        Fetch(
            url,
            token,
            (result) => {
                const users = convertSubscriptions(convertIdToKey(result.users))
                users.forEach(user => {
                    if (!user.mobile) {
                        user.mobile = "-"
                    }

                    if (!user.email) {
                        user.email = "-"
                    }
                })
                setUsers(users)
                setPagination({ ...paginationParam, total: result.total })
            }
        )
    }

    const handleSearch = (searchText) => {
        if (searchText) {
            Fetch(
                `${apiUrl}/users?search_text=${searchText}`,
                token,
                (result) => {
                    const users = convertSubscriptions(convertIdToKey(result.users))
                    users.forEach(user => {
                        if (!user.mobile) {
                            user.mobile = "-"
                        }

                        if (!user.email) {
                            user.email = "-"
                        }
                    })
                    setUsers(users)
                }
            )
        }
    }

    const cancelSubscription = () => {
        try {
            if (!reason) {
                notification.error({
                    message: 'Cancellation unsuccessful',
                    description: 'reason is mandatory'
                })
                return
            }
            Post(
                `${apiUrl}/users/${user}/cancel_subscription`,
                token,
                { reason },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Subscription cancelled successfully'
                        })
                        setFilters({ date: [null, null], activeSubscription: null })
                    }
                },
                () => {
                    notification.error({
                        message: 'Error removing subscription',
                        description: 'Please check console for more details'
                    })
                }
            )
        } finally {
            setReason("")
            setModalVisible(false)
        }
    }

    return (
        <div className="manage-episodes-wrapper">
            <Row>
                <Col span={6}>
                    <Select
                        placeholder="Enter User Mobile or Email"
                        showSearch
                        allowClear
                        value={searchValue}
                        onChange={handleSearchChange}
                        onSearch={handleSearch}
                        notFoundContent={null}
                        onClear={() => { handleTableDataChange({ current: 1, pageSize: 10 }); setSearchValue(null) }}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={1} />
                <Col span={4}>
                    <Select
                        placeholder="Subscription"
                        allowClear
                        onChange={(e) => { setFilters({ ...filters, activeSubscription: e }); setPagination({ current: 1, pageSize: 10 }) }}
                        notFoundContent={null}
                        onClear={() => { setFilters({ ...filters, activeSubscription: null }); setPagination({ current: 1, pageSize: 10 }) }}
                        style={{ width: '100%' }}
                    >
                        <Option value={"true"}>Yes</Option>
                        <Option value={"false"}>No</Option>
                    </Select>
                </Col>
                <Col span={1} />
                <Col span={6}>
                    <DatePicker.RangePicker
                        disabledDate={disabledDate}
                        format={dateFormat}
                        defaultValue={filters.date}
                        onChange={(_, dateString) => { setFilters({ ...filters, date: dateString ? dateString : [null, null] }); setPagination({ current: 1, pageSize: 10 }) }}
                        renderExtraFooter={() => 'Filter by user creation date'}
                    />
                </Col>
            </Row>
            <Table
                dataSource={users}
                pagination={pagination}
                onChange={handleTableDataChange}
            >
                <Column title="Name" dataIndex="name" key="name" align="center" render={(_, record) => record.suspended ? "Deleted User" : record.name} />
                <Column title="Email" dataIndex="email" key="email" align="center" render={(_, record) => record.suspended ? "Deleted User" : record.email} />
                <Column title="Mobile" dataIndex="mobile" key="mobile" align="center" render={(_, record) => record.suspended ? "Deleted User" : record.mobile} />
                <Column title="Created At" dataIndex="createdAt" key="createdAt" align="center" render={(_, record) => record.suspended ? "NA" : moment(record.createdAt).format(dateFormat)} />
                <Column title="Subscription" dataIndex="subscription" key="subscription" align="center" render={(_, record) => record.suspended ? "Deleted User" : record.subscription} />
                <Column title="Actions" dataIndex="actions" key="actions" align="center" render={(_, record) => record.suspended ? "NA" : ((superAdmin && record.activeSubscription) ? <a onClick={() => { setUser(record.key); setModalVisible(true) }}>Cancel Subscription</a> : '-')} />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'users')} />
            <Modal
                title="Enter reason for cancellation"
                visible={modalVisible}
                onOk={() => cancelSubscription()}
                onCancel={() => { setReason(""); setModalVisible(false) }}
                maskClosable={false}
                okText="Confirm Cancellation"
                cancelText="go back"
            >
                <Input onChange={e => setReason(e.target.value)} />
            </Modal>
        </div>
    )
}

export default ManageUsers
