import { Table, Select } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CloudDownloadOutlined } from '@ant-design/icons'

import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey, convertSubscriptions, handleExportClick } from '../../utils'

const { Column } = Table
const { Option } = Select

const SubMonitor = () => {
    const { token, apiUrl } = useContext(Context)

    const [users, setUsers] = useState([])
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    const [searchValue, setSearchValue] = useState()

    const handleSearchChange = (searchText) => {
        setSearchValue(searchText)
    }

    const handleTableDataChange = (paginationParam) => {
        Fetch(
            `${apiUrl}/users?current=${paginationParam.current}&size=${paginationParam.pageSize}`,
            token,
            (result) => {
                const users = convertSubscriptions(convertIdToKey(result.users))
                users.forEach(user => {
                    if (!user.mobile) {
                        user.mobile = "-"
                    }

                    if (!user.email) {
                        user.email = "-"
                    }
                })
                setUsers(users)
                setPagination({ ...paginationParam, total: result.total })
            }
        )
    }

    const handleSearch = (searchText) => {
        if (searchText) {
            Fetch(
                `${apiUrl}/users?searchText=${searchText}`,
                token,
                (result) => {
                    const users = convertSubscriptions(convertIdToKey(result.users))
                    users.forEach(user => {
                        if (!user.mobile) {
                            user.mobile = "-"
                        }

                        if (!user.email) {
                            user.email = "-"
                        }
                    })
                    setUsers(users)
                }
            )
        }
    }

    return (
        <div className="manage-episodes-wrapper">
            <Select
                placeholder="Select Plan"
                showSearch
                allowClear
                value={searchValue}
                onChange={handleSearchChange}
                onSearch={handleSearch}
                notFoundContent={null}
            />
            <Select
                placeholder="Subscription filter"
                showSearch
                allowClear
                value={searchValue}
                onChange={handleSearchChange}
                onSearch={handleSearch}
                notFoundContent={null}
            >
                <Option value={'active'}> Active</Option>
                <Option value={2}> Ending in 2 days</Option>
                <Option value={10}> Ending in 10 days</Option>
                <Option value={'expired'}>Expired</Option>
            </Select>
            
            <Table
                dataSource={users}
                pagination={pagination}
            >
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Email" dataIndex="email" key="email" align="center" />
                <Column title="Mobile" dataIndex="mobile" key="mobile" align="center" />
                <Column title="Plan Type" dataIndex="planType" key="planType" align="center" />
                <Column title="Plan Name" dataIndex="planName" key="planName" align="center" />
                <Column title="Start date" dataIndex="startDate" key="startDate" align="center" />
                <Column title="End date" dataIndex="endDate" key="endDate" align="center" />
            </Table>
        </div>
    )
}

export default SubMonitor;
